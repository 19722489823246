@import '../../../app.scss';

.transaction-details {
  height: auto;
  background-color: var(--general-background-light) !important;
  [data-theme='dark'] & {
    background-color: var(--general-background-dark) !important;
    color: $zarph-white;
  }

  .mantine-Grid-col {
    padding: 0.5vh 0.5vw 0.5vh 0;
    color: var(--text-color-light);
    [data-theme='dark'] & {
      color: var(--text-color-dark);
    }
  }
  .mantine-Title-root {
    color: var(--title-color-light);
    [data-theme='dark'] & {
      color: var(--title-color-dark);
    }
  }

  .mantine-Button-root {
    background-color: var(--background-color-light);
    color: $zarph-white;
    [data-theme='dark'] & {
      background-color: var(--background-color-dark);
      color: $zarph-white;
    }
    svg {
      color: $zarph-white;
      margin-right: 0.5vw;
    }
  }
}
