@import '../../../app.scss';

.bankTransactions {
  position: absolute;
  left: 3vw;
  top: 5vh;
  width: 88vw;
  height: 100%;
  background-color: var(--general-background-light);
  [data-theme='dark'] & {
    background-color: var(--general-background-dark);
  }
  @media only screen and (max-width: 575px) {
    left: 5vw;
    top: 12vh;
  }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    top: 2vh;
    left: 10vw;
  }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    top: 2vh;
    left: 10vw;
  }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    top: 2vh;
    left: 10vw;
  }
  @media only screen and (min-width: 1200px) and (max-width: 1599px) {
    width: 92vw;
    left: 7vw;
    top: 2vh;
  }
  @media only screen and (min-width: 1600px) {
    width: 94vw;
    left: 5vw;
    top: 2vh;
  }
  @media only screen and (min-width: 2560px) {
    width: 94vw;
    left: 4vw;
    top: 2vh;
  }
  .mantine-Table-table {
    width: 100%;
    text-align: center;

    .mantine-Table-thead {
      width: 100%;
      .mantine-Table-th {
        text-align: center;
        width: 5%;
        color: var(--text-color-light);
        [data-theme='dark'] & {
          color: var(--text-color-dark);
        }
      }
    }
    svg {
      color: var(--text-color-light) !important;
      [data-theme='dark'] & {
        color: var(--text-color-dark) !important;
      }
    }
  }
  .mantine-Button-root {
    background-color: var(--background-color-light);
    color: $zarph-white;
    [data-theme='dark'] & {
      background-color: var(--background-color-dark);
      color: $zarph-white;
    }
    svg {
      color: $zarph-white;
      margin-right: 0.5vw;
    }
  }
}
