@import '../../../app.scss';

.locals {
  position: absolute;
  left: 3vw;
  top: 5vh;
  width: 88vw;
  height: 100%;
  background-color: var(--general-background-light);
  [data-theme='dark'] & {
    background-color: var(--general-background-dark);
  }

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  @media only screen and (max-width: 575px) {
    left: 5vw;
    top: 12vh;
    flex-direction: column;
  }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    top: 2vh;
    left: 10vw;
    flex-direction: column;
  }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    top: 2vh;
    left: 10vw;
  }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    top: 2vh;
    left: 10vw;
  }
  @media only screen and (min-width: 1200px) and (max-width: 1599px) {
    width: 92vw;
    left: 7vw;
    top: 2vh;
  }
  @media only screen and (min-width: 1600px) {
    width: 94vw;
    left: 5vw;
    top: 4vh;
  }
  @media only screen and (min-width: 2560px) {
    width: 94vw;
    left: 4vw;
    top: 4vh;
  }
  .mantine-InputWrapper-root {
    margin-top: 1vh;
  }

  .locals-table {
    width: 28vw;
    margin-right: 2vw;
    @media only screen and (max-width: 575px) {
      width: 60vw;
    }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      width: 60vw;
    }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      width: 36vw;
      margin-right: 4vw;
    }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      width: 30vw;
      margin-right: 4vw;
    }
    @media only screen and (min-width: 1200px) and (max-width: 1599px) {
      width: 30vw;
      margin-right: 4vw;
    }
    @media only screen and (min-width: 1600px) {
      width: 28vw;
      margin-right: 2vw;
    }
    @media only screen and (min-width: 2560px) {
      width: 25vw;
      margin-right: 2vw;
    }
  }

  .locals-edit {
    width: 40vw;
    @media only screen and (max-width: 575px) {
      width: 85vw;
    }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      width: 82vw;
    }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      width: 44vw;
    }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      width: 50vw;
    }
    @media only screen and (min-width: 1200px) and (max-width: 1599px) {
      width: 65vw;
      .mantine-TextInput-root {
        width: 55vw;
      }
    }
    @media only screen and (min-width: 1600px) {
      width: 65vw;
      .mantine-TextInput-root {
        width: 60vw;
      }
    }
    @media only screen and (min-width: 2560px) {
      .mantine-TextInput-root {
        width: 50vw;
      }
    }
  }

  .locals-currency-table {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-content: flex-start;
    .mantine-TextInput-root {
      width: 75%;
    }
    .mantine-Table-table {
      width: 50vw;
    }
  }
}

@media only screen and (max-width: 575px) {
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
}
@media only screen and (min-width: 1200px) and (max-width: 1599px) {
}
@media only screen and (min-width: 1600px) {
}
@media only screen and (min-width: 2560px) {
}
