@import '../../../app.scss';

.show-equipment-details {
  height: auto;
  display: flex;
  flex-direction: column;
  .mantine-Grid-col {
    padding: 0.5vh 0.5vw 0.5vh 0;
    width: 100%;
  }
  .onlineStatus,
  .inService {
    color: #538539 !important;
  }

  .offlineStatus,
  .notInService {
    color: #861724 !important;
  }
}

.status-table {
  width: 75%;
  margin: 2vh auto;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 575px) {
  .show-equipment-details {
    .mantine-Grid-inner {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 0.5rem;
    }
  }
  .status-table {
    width: 100%;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up)  */
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .show-equipment-details {
    .mantine-Grid-inner {
      display: grid;
      grid-template-columns: repeat(3, 1fr) !important;
      gap: 0.5rem;
    }
  }
  .status-table {
    width: 100%;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .show-equipment-details {
    .mantine-Grid-inner {
      display: grid;
      grid-template-columns: repeat(4, 1fr) !important;
      gap: 0.5rem;
    }
  }
  .status-table {
    width: 80%;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .show-equipment-details {
    .mantine-Grid-inner {
      display: grid;
      grid-template-columns: repeat(3, 1fr) !important;
      gap: 0.5rem;
    }
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
}

@media only screen and (min-width: 2560px) {
}
