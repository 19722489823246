@import "../../../app.scss";
.mantine-Modal-header {
    background-color:  var(--background-color-light) !important;
    color: $zarph-white;
    [data-theme='dark'] & {
      background-color:  var(--background-color-dark) !important;
      color: $zarph-white;
    }   
    padding: 2vw 2vh 2vw 2vh !important;
    @media only screen and (max-width: 575px) {
        padding: 3vh 3vw 3vh 1vw !important;
    }
    
    @media only screen and (min-width: 576px) and (max-width: 767px) {
        padding: 5vh 3vw 5vh 3vw !important;
    }
    
    @media only screen and (min-width: 768px) and (max-width: 991px) {
        padding: 4vh 2vw 4vh 2vw !important;
    }
    
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
        padding: 4vh 2vw 4vh 2vw !important;
    }
    
    @media only screen and (min-width: 1200px) and (max-width: 1599px) {
        padding: 3vh 2vw 3vh 2vw !important;
    }
    
    @media only screen and (min-width: 1600px) {
        padding: 3vh 1vw 3vh 1vw !important;
    }
    
    @media only screen and (min-width: 2560px) {
        padding: 3vh 1vw 3vh 1vw !important;
    }
}

.mantine-Modal-title{
    font-weight: 500 !important;
    font-size: 1.5vw;

    @media only screen and (max-width: 575px) {
        font-size: 6vw !important;
      }
      
      @media only screen and (min-width: 576px) and (max-width: 767px) {
        font-size: 3.8vw !important;
      }
      
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        font-size: 3.2vw !important;
      }
      
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        font-size: 2.7vw !important;
      }
      
      @media only screen and (min-width: 1200px) and (max-width: 1599px) {
        font-size: 2vw !important;
      }
      
      @media only screen and (min-width: 1600px) {
        font-size: 1.6vw !important;
      }
      
      @media only screen and (min-width: 2560px) {
        font-size: 1vw !important;
      }

    span{
      @media only screen and (max-width: 575px) {
        font-size: 3.5vw !important;
      }
      
      @media only screen and (min-width: 576px) and (max-width: 767px) {
        font-size: 2.5vw !important;
      }
      
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        font-size: 2.5vw !important;
      }
      
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        font-size: 2.2vw !important;
      }
      
      @media only screen and (min-width: 1200px) and (max-width: 1599px) {
        font-size: 1.6vw !important;
      }
      
      @media only screen and (min-width: 1600px) {
        font-size: 1.2vw !important;
      }
      
      @media only screen and (min-width: 2560px) {
        font-size: 0.6vw !important;
      }    }

}

.mantine-Modal-close {
    color: #f8f9fa !important;
}

.mantine-Modal-close :hover {
    color: #1d5580;
}
.mantine-Modal-content{
  .mantine-Button-root{
    background-color:  var(--background-color-light);
    color: $zarph-white;
    [data-theme='dark'] & {
      background-color:  var(--background-color-dark);
      color: $zarph-white;
    } 
} 
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 575px) {
    .mantine-Modal-title {
        font-size: 6vw;
        margin-left: 5vw !important;
    }

    .header-details span {
        font-size: 3vw;
        margin-left: 5vw;
    }

    .header-details span:last-child {
        margin-left: 0;
    }
}

  
  
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 576px) and (max-width: 767px) {
  }
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
  }
  
  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
  }
  
  @media only screen and (min-width: 1200px) and (max-width: 1399px) {
  }
  
  @media only screen and (min-width: 2560px) {
  }
  