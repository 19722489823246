@import '../../../app.scss';

.equipment-currency {
  position: absolute;
  left: 3vw;
  top: 5vh;
  width: 88vw;
  height: 100%;
  background-color: var(--general-background-light);
  [data-theme='dark'] & {
    background-color: var(--general-background-dark);
  }
  @media only screen and (max-width: 575px) {
    left: 5vw;
    top: 12vh;
  }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    top: 2vh;
    left: 10vw;
  }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    top: 2vh;
    left: 10vw;
  }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    top: 2vh;
    left: 10vw;
  }
  @media only screen and (min-width: 1200px) and (max-width: 1599px) {
    width: 92vw;
    left: 7vw;
    top: 2vh;
  }
  @media only screen and (min-width: 1600px) {
    width: 94vw;
    left: 5vw;
    top: 2vh;
  }
  @media only screen and (min-width: 2560px) {
    width: 94vw;
    left: 4vw;
    top: 5vh;
  }
  .onlineStatus {
    display: flex;
    justify-content: center;
    color: #538539 !important;
  }

  .offlineStatus {
    display: flex;
    justify-content: center;
    color: #861724;
  }

  .mantine-Table-table {
    .mantine-Table-thead {
      .mantine-Table-th {
        color: var(--title-color-light);
        [data-theme='dark'] & {
          color: var(--title-color-dark);
        }
      }
    }
    svg {
      color: var(--title-color-light) !important;
      [data-theme='dark'] & {
        color: var(--title-color-dark) !important;
      }
    }
  }

  .mantine-Group-root {
    display: flex;
    align-items: flex-end;
  }

  .mantine-InputWrapper-root {
    .mantine-TextInput-wrapper {
      width: 10vw;
      .mantine-TextInput-input {
        text-align: center;
      }
      @media only screen and (max-width: 575px) {
        width: 30vw;
      }

      @media only screen and (min-width: 576px) and (max-width: 767px) {
        width: 24vw;
      }

      @media only screen and (min-width: 768px) and (max-width: 991px) {
        width: 16vw;
      }

      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        width: 16vw;
      }

      @media only screen and (min-width: 1200px) and (max-width: 1599px) {
        width: 12vw;
      }

      @media only screen and (min-width: 1600px) {
        width: 10vw;
      }

      @media only screen and (min-width: 2560px) {
        width: 6vw;
      }
    }
  }

  .mantine-Table-table {
    .input {
      display: flex;
      justify-content: center;
    }
  }
  .mantine-Button-root {
    background-color: var(--background-color-light);
    color: $zarph-white;
    [data-theme='dark'] & {
      background-color: var(--background-color-dark);
      color: $zarph-white;
    }
    @media only screen and (max-width: 575px) {
      width: 50vw;
    }

    @media only screen and (min-width: 576px) and (max-width: 767px) {
      width: 34vw;
    }

    @media only screen and (min-width: 768px) and (max-width: 991px) {
      width: 30vw;
    }

    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      width: 24vw;
    }

    @media only screen and (min-width: 1200px) and (max-width: 1599px) {
      width: 26vw;
    }

    @media only screen and (min-width: 1600px) {
      width: 2vw;
    }

    @media only screen and (min-width: 2560px) {
      width: 10vw;
    }
  }
}

.XLS-button {
  @media only screen and (max-width: 575px) {
    width: 30vw !important;
  }

  @media only screen and (min-width: 576px) and (max-width: 767px) {
    width: 24vw !important;
  }

  @media only screen and (min-width: 768px) and (max-width: 991px) {
    width: 16vw !important;
  }

  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    width: 16vw !important;
  }

  @media only screen and (min-width: 1200px) and (max-width: 1599px) {
    width: 12vw !important;
  }

  @media only screen and (min-width: 1600px) {
    width: 10vw !important;
  }

  @media only screen and (min-width: 2560px) {
    width: 6vw !important;
  }
  svg {
    @media only screen and (max-width: 575px) {
      margin-right: 1.5vw;
    }

    @media only screen and (min-width: 576px) and (max-width: 767px) {
      margin-right: 1vw;
    }

    @media only screen and (min-width: 768px) and (max-width: 991px) {
      margin-right: 0.8vw;
    }

    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      margin-right: 0.6vw;
    }

    @media only screen and (min-width: 1200px) and (max-width: 1599px) {
      margin-right: 0.4vw;
    }

    @media only screen and (min-width: 1600px) {
      margin-right: 0.4vw;
    }

    @media only screen and (min-width: 2560px) {
      margin-right: 0.4vw;
    }
  }
}

.save-button {
  background-color: #538539 !important;

  @media only screen and (max-width: 575px) {
    width: 30vw !important;
  }

  @media only screen and (min-width: 576px) and (max-width: 767px) {
    width: 24vw !important;
  }

  @media only screen and (min-width: 768px) and (max-width: 991px) {
    width: 16vw !important;
  }

  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    width: 16vw !important;
  }

  @media only screen and (min-width: 1200px) and (max-width: 1599px) {
    width: 12vw !important;
  }

  @media only screen and (min-width: 1600px) {
    width: 10vw !important;
  }

  @media only screen and (min-width: 2560px) {
    width: 6vw !important;
  }
}
