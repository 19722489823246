@import '../../../app.scss';

.equipment-config-form {
  position: absolute;
  left: 3vw;
  top: 5vh;
  width: 88vw;
  height: 100%;
  background-color: var(--general-background-light);
  [data-theme='dark'] & {
    background-color: var(--general-background-dark);
  }
  @media only screen and (max-width: 575px) {
    left: 5vw;
    top: 12vh;
  }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    top: 2vh;
    left: 10vw;
  }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    top: 2vh;
    left: 10vw;
  }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    top: 2vh;
    left: 10vw;
  }
  @media only screen and (min-width: 1200px) and (max-width: 1599px) {
    width: 92vw;
    left: 7vw;
    top: 2vh;
  }
  @media only screen and (min-width: 1600px) {
    width: 94vw;
    left: 5vw;
    top: 2vh;
  }
  @media only screen and (min-width: 2560px) {
    width: 94vw;
    left: 4vw;
    top: 5vh;
  }

  .mantine-Select-root {
    width: 13vw;
    margin: 2vh 0 2vh 0;
    @media only screen and (max-width: 575px) {
      width: 35vw;
    }

    @media only screen and (min-width: 576px) and (max-width: 767px) {
      width: 25vw;
    }

    @media only screen and (min-width: 768px) and (max-width: 991px) {
      width: 20vw;
    }

    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      width: 18vw;
    }

    @media only screen and (min-width: 1200px) and (max-width: 1599px) {
      width: 15vw;
    }

    @media only screen and (min-width: 1600px) {
      width: 12vw;
    }

    @media only screen and (min-width: 2560px) {
      width: 10vw;
    }

    .mantine-Select-label {
      margin-bottom: 1vh;
      @media only screen and (max-width: 575px) {
        font-size: large;
        width: 38vw;
      }
      @media only screen and (min-width: 576px) and (max-width: 767px) {
        font-size: large;
        width: 38vw;
      }

      @media only screen and (min-width: 768px) and (max-width: 991px) {
        font-size: medium;
      }

      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        font-size: medium;
      }

      @media only screen and (min-width: 1200px) and (max-width: 1599px) {
        font-size: medium;
      }

      @media only screen and (min-width: 1600px) {
        font-size: medium;
      }

      @media only screen and (min-width: 2560px) {
        font-size: medium;
      }
    }
  }

  .mantine-Table-table {
    width: 35vw;
    margin-bottom: 2vh;
    @media only screen and (max-width: 575px) {
      width: 85vw;
    }

    @media only screen and (min-width: 576px) and (max-width: 767px) {
      width: 50vw;
    }

    @media only screen and (min-width: 768px) and (max-width: 991px) {
      width: 55vw;
    }

    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      width: 60vw;
    }

    @media only screen and (min-width: 1200px) and (max-width: 1599px) {
      width: 40vw;
    }

    @media only screen and (min-width: 1600px) {
      width: 35vw;
    }

    @media only screen and (min-width: 2560px) {
      width: 30vw;
    }

    .mantine-Table-thead {
      .mantine-Table-th {
        color: var(--title-color-light);
        [data-theme='dark'] & {
          color: var(--title-color-dark);
        }
      }
    }
    svg {
      color: var(--title-color-light) !important;
      [data-theme='dark'] & {
        color: var(--title-color-dark) !important;
      }
    }
  }

  .mantine-Grid-root {
    width: 50vw;
    margin-top: 4vh;
    @media only screen and (max-width: 575px) {
      width: 85vw;
    }

    @media only screen and (min-width: 576px) and (max-width: 767px) {
      width: 84vw;
    }

    @media only screen and (min-width: 768px) and (max-width: 991px) {
      width: 75vw;
    }

    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      width: 70vw;
    }

    @media only screen and (min-width: 1200px) and (max-width: 1599px) {
    }

    @media only screen and (min-width: 1600px) {
    }

    @media only screen and (min-width: 2560px) {
      width: 40vw;
    }

    .mantine-Grid-inner {
      .mantine-Grid-col {
        .mantine-Select-root,
        .mantine-TextInput-root {
          margin-bottom: 2vh;

          .mantine-Select-label,
          .mantine-TextInput-label {
            margin-bottom: 1vh;
            @media only screen and (max-width: 575px) {
              font-size: large;
              width: 160px;
            }

            @media only screen and (min-width: 576px) and (max-width: 767px) {
              font-size: large;
              width: 150px;
            }

            @media only screen and (min-width: 768px) and (max-width: 991px) {
              font-size: medium;
            }

            @media only screen and (min-width: 992px) and (max-width: 1199px) {
              font-size: medium;
            }

            @media only screen and (min-width: 1200px) and (max-width: 1599px) {
              font-size: medium;
            }

            @media only screen and (min-width: 1600px) {
              font-size: medium;
            }

            @media only screen and (min-width: 2560px) {
            }
          }
        }
      }
    }
  }

  .mantine-Button-root {
    margin: 2vh 0 2vh 0;
    font-size: medium;

    background-color: var(--background-color-light);
    color: $zarph-white;
    [data-theme='dark'] & {
      background-color: var(--background-color-dark);
      color: $zarph-white;
    }
    @media only screen and (max-width: 575px) {
      margin: 2vh 0 4vh 0;
      width: 38vw;
    }

    @media only screen and (min-width: 576px) and (max-width: 767px) {
      margin: 2vh 0 4vh 0;
      width: 35vw;
    }

    @media only screen and (min-width: 768px) and (max-width: 991px) {
      margin: 2vh 0 4vh 0;
      width: 32vw;
    }

    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      margin: 2vh 0 4vh 0;
      width: 15vw;
    }

    @media only screen and (min-width: 1200px) and (max-width: 1599px) {
      margin: 2vh 0 4vh 0;
      width: 12vw;
    }

    @media only screen and (min-width: 1600px) {
      margin: 2vh 0 4vh 0;
      width: 8vw;
    }

    @media only screen and (min-width: 2560px) {
      margin: 2vh 0 4vh 0;
      width: 8vw;
    }
  }
}
